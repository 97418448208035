"use client";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Link from "next/link";
import { createBrowserClient } from "@supabase/ssr";
import { useRouter } from "next/navigation";

const loginSchema = z.object({
  email: z
    .string()
    .min(1, "L'email est requis")
    .email("Format d'email invalide"),
  password: z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
});

type LoginFormData = z.infer<typeof loginSchema>;

export function LoginForm() {
  const router = useRouter();
  const [error, setError] = useState<string | null>(null);

  const supabase = createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LoginFormData) => {
    setError(null);
    try {
      const validatedData = loginSchema.parse(data);
      const { email, password } = validatedData;

      const { error: signInError, data: signInData } =
        await supabase.auth.signInWithPassword({
          email,
          password,
        });

      if (signInError) {
        throw new Error(signInError.message);
      }

      if (signInData?.session) {
        reset();
        router.push("/dashboard");
        router.refresh();
      }
    } catch (error: any) {
      if (error.errors) {
        setError(
          error.errors.map((e: any) => e.message).join(", ") ||
            "Erreur inconnue",
        );
      } else {
        setError(error.message || "Une erreur est survenue.");
      }
      console.error("Erreur de connexion:", error);
    }
  };

  return (
    <div className="mx-auto w-full max-w-md space-y-6 p-6">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="space-y-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium uppercase text-gray-800"
          >
            Email
          </label>
          <input
            {...register("email")}
            type="email"
            id="email"
            className="w-full border-2 border-black bg-transparent px-3 py-2 text-black focus:outline-none focus:ring-2 focus:ring-gold"
            disabled={error !== null}
          />
          {errors.email && (
            <p className="text-sm text-red-500">{errors.email.message}</p>
          )}
        </div>

        <div className="space-y-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium uppercase text-gray-800"
          >
            Mot de passe
          </label>
          <input
            {...register("password")}
            type="password"
            id="password"
            className="w-full border-2 border-black bg-transparent px-3 py-2 text-black focus:outline-none focus:ring-2 focus:ring-gold"
            disabled={error !== null}
          />
          {errors.password && (
            <p className="text-sm text-red-500">{errors.password.message}</p>
          )}
        </div>

        {error && <p className="text-sm text-red-500">{error}</p>}
        {/* <div className="flex justify-end">
          <Link
            href="/forgot-password"
            className="text-sm text-gold hover:underline"
          >
            Mot de passe oublié ?
          </Link>
        </div> */}

        <button
          type="submit"
          disabled={error !== null}
          className="w-full rounded-md bg-gold px-4 py-2 text-white transition-colors hover:bg-gold/90 disabled:cursor-not-allowed disabled:opacity-50"
        >
          {error ? "Connexion..." : "Se connecter"}
        </button>
      </form>
    </div>
  );
}
